import React from "react"

import { CategoriesFilterPanel } from "../../components/blog/categories-filter-panel"
import { Hero } from "../../components/blog/hero"
import { PostSidebar } from "../../components/blog/post-sidebar"
import { Breadcrumbs, BreadcrumbsItem } from "../../components/breadcrumbs"
import { Container } from "../../components/container"
import SEO from "../../components/seo"
import img1 from "./images/zdrava-krabicka-denní-menu.jpg"
import styles from "./post-grid.module.css"

const ZdravaKrabickaDenníMenu = () => {
  return (
    <div className={styles.wrapper}>
      <SEO
        title={"Zdravá krabička jako ideální oběd do kanceláře | NutritionPro"}
        description={
          "Objednejte si denní menu přímo do kanceláře. Máme pro vás zdravé a nutričně vyvážené teplé obědy a večeře. Teď už žádné rychlé občerstvení."
        }
      />
      <Container>
        <Breadcrumbs style={{ margin: "24px 0" }}>
          <BreadcrumbsItem link="/">Domu</BreadcrumbsItem>
          <BreadcrumbsItem link="/blog/posts">Blog</BreadcrumbsItem>
          <BreadcrumbsItem link="/blog/zdrave-udrzitelne-hubnuti">
            Zdravá krabička jako ideální oběd do kanceláře
          </BreadcrumbsItem>
        </Breadcrumbs>
      </Container>
      <Container>
        <div className={styles.postGrid}>
          <div>
            <Hero
              title="Zdravá krabička jako ideální oběd do kanceláře"
              date="16.05.22"
            />
            <div>
              <p className={styles.postText}>
                Snažíte se jíst zdravě, ale pomalu vám dochází nápady, co zrovna
                vařit?{" "}
                <b>Vymýšlet jídelníček na celý měsíc rozhodně není snadné</b>.
                Pokud si můžete dát lehčí a nutričně vyvážené jídlo v práci,
                například v kantýně, patříte mezi ty šťastnější.{" "}
                <b>
                  Věděli jste ale, že existuje snadné řešení, jak si dopřát
                  každý den nové, nutričně vyvážené a zdravé jídlo
                </b>
                ? Zkuste naše denní menu.
              </p>
              <img
                src={img1}
                className={styles.postImg}
                style={{ marginBottom: "40px" }}
                alt="img1"
              />
              <h5 className={styles.postTitle}>
                Už žádná rychlá jídla, ze kterých přiberete
              </h5>
              <p className={styles.postText}>
                Určitě jste nejednou v kanceláři zahnali hlad rychlým jídlem, a
                ne příliš zdravým. Ukazuje se, že právě{" "}
                <b>
                  v kancelářích je velké riziko sklonu ke špatné životosprávě
                </b>
                . Hektické schůzky, mnoho práce a rázem zjistíte, že prostor na
                oběd jste strávili za počítačem. Jak to vyřešíte? Zajdete si na
                něco rychlého. Ovšem pokud se to často opakuje, můžete nakonec
                zjistit, <b>že jste za pouhý rok nepěkně přibrali</b>. Přitom
                existuje snadné řešení – <b>dovoz zdravého obědu</b>.
              </p>
              <h5 className={styles.postTitle}>Řešení v podobě Office Packu</h5>
              <p className={styles.postText}>
                <b>Zdravé a nutričně hodnotné obědy nebo večeře</b>. Představte
                si, že můžete mít 5 dní v týdnu nejen teplé obědy, ale i večeře.
                Přitom vás to nebude stát ani minutu vašeho času. Zdarma vám
                jídla dovezeme každou neděli, úterý a čtvrtek.{" "}
                <b>
                  Velké porce, ze kterých se najíte, ale nebudete zbytečně
                  přibírat
                </b>
                . Tak snadné může být vyřešit nedostatek času na přípravu obědu
                do práce.
              </p>
              <p className={styles.postText}>
                <em>
                  Objevte jedinečné denní menu v podobě{" "}
                  <a href="https://nutritionpro.cz/products/product-4/">
                    Office Packu od NutritionPro
                  </a>
                  .
                </em>
              </p>
              <p className={styles.postText}>
                Vyzkoušejte naše <b>krabičkové polední menu v Praze</b> a{" "}
                <b>nebudete litovat</b>Na rok pro vás máme připravenou zásobu
                zdravých jídel, kterou se snažíme průběžně obměňovat. Stále tak
                můžete mít nové obědy a večeře, aniž by vás omrzely. Navíc{" "}
                <b>složení jídel vytváří odbornice – naše dietoložka.</b>
              </p>
              <h5 className={styles.postTitle}>
                Zapomeňte na jezení před počítačem
              </h5>
              <p className={styles.postText}>
                Možná se poznáváte ve zlozvyku jezení před počítačem. Jenže
                víte, že takové jezení není vůbec zdravé?{" "}
                <b>Obědvání před monitorem vašeho počítače vede k přejídání</b>,
                a tedy nadměrnému příjmu kalorií, které si tělo ukládá do tuků.
                Nesnažte se ušetřit čas tím, že se najíte u počítače, protože
                studie ukazují, že pokud zhltnete jídlo v rychlosti u počítače,
                mozek si vůbec neuvědomí, že jste jedli a velmi rychle budete
                mít opět pocit hladu. Investujte alespoň trochu času do svého
                zdraví.
              </p>
            </div>
          </div>
          <PostSidebar />
        </div>
      </Container>
    </div>
  )
}

export default ZdravaKrabickaDenníMenu
